<template>
  <FormItem #="{ update, mode, attrs, value }" class="as-flex">
  <textarea
    v-bind="attrs"
    :value="value"
    v-on:[mode]="update($event.target.value)"
    class="textarea flex-1"
    autocomplete="off"
  />
  </FormItem>
</template>

<script lang="ts">
import * as R from 'ramda'
import FormItem from '../FormItem.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Textarea',
  components: { FormItem },
  setup (props, ctx) {
    return {
      attrs: R.pick(['name', 'text'], ctx.attrs),
    }
  },
})
</script>
<style scoped>
.info {
  box-sizing: border-box;
}

.textarea {
  outline-style: none;
  resize: none;
  background: none;
  appearance: none;
  border: none;
//color: var(--white); font-size: inherit;
  width: 100%;
  font-family: inherit;
}
</style>
