/**
* @author ayou[beica1@outook.com]
* @date 2022/1/27 16:50
* @description
*   TheEmailInput.vue of WeTrade
*/
<template>
<t custom #="{t}">
  <FormInput name="email" :default-value="defaultValue"
    :placeholder="t('personalinfo_6') || 'Email'"
    class="block" />
</t>
</template>

<script lang="ts">
import { keymap } from '@/config'
import FormInput from 'common/form/inputs/FormInput.vue'
import { localGet } from 'essential/store/localStore'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheEmailInput',
  components: { FormInput },
  setup () {
    return {
      defaultValue: localGet(keymap.user.email),
    }
  },
})
</script>

<style scoped>

</style>
