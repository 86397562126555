
import Button from '@/components/Button.vue'
import { keymap } from '@/config'
import TheEmailInput from '@/modules/in/components/TheEmailInput.vue'
import { getBindFormSchema, useBindPayer } from '@/modules/in/in'
import { sendBindCode } from '@/modules/user/user.api'
import TheMobileInput from '@/pages/login/components/TheMobileInput.vue'
import TheCodeInput from '@/pages/register/components/TheCodeInput.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { YesOrNo, Data } from '@/types'
import Form from 'common/form/Form.vue'
import FInput from 'common/form/inputs/FormInput.vue'
import Textarea from 'common/form/inputs/Textarea.vue'
import { localGet } from 'essential/store/localStore'
import { defineComponent, ref } from 'vue'
import * as R from 'ramda'

export default defineComponent({
  name: 'FillInUpFor63',
  components: { TheEmailInput, Textarea, TheMobileInput, Button, Form, FInput, TheCodeInput, PageWithHeader },
  setup () {
    const noMobileBind = Number(localGet(keymap.user.mobileBindStatus)) === YesOrNo.NO
    const { request, progress } = useBindPayer()
    const form = ref<HTMLFormElement | null>(null)

    const reSetSchema = () => {
      const schema = getBindFormSchema(noMobileBind)
      return {
        ...schema,
        address: {
          pattern: /\w+/,
        },
      }
    }

    return {
      form,
      noMobileBind,
      schema: reSetSchema,
      sendCode (cc: string, mobile: string, cb: () => void) {
        return sendBindCode(cc, mobile).then(cb)
      },
      bind (data: Data) {
        const noBlankData = R.mapObjIndexed(R.trim, data as Record<string, string>)
        return request(noBlankData, noMobileBind)
      },
      progress,
      submit () {
        (form.value as HTMLFormElement).submit()
      },
    }
  },
})
